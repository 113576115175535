<template>
  <div class="box">
    <p class="boxTitle">ติดตามออเดอร์และสินค้า</p>
    <div class="items">
      <div @click="$router.push('/order/confirm')">
        <b class="danger">{{
          order.confirmOrder >= 0 ? order.confirmOrder.toLocaleString() : "-"
        }}</b>
        <p>ที่ต้องยืนยัน ></p>
      </div>
      <div @click="$router.push('/order/progress')">
        <b class="warning">{{
          order.payment >= 0 ? order.payment.toLocaleString() : "-"
        }}</b>
        <p>รอลูกค้าชำระ ></p>
      </div>
      <div @click="$router.push('/order/progress')">
        <b class="danger">{{
          order.confirmPayment >= 0
            ? order.confirmPayment.toLocaleString()
            : "-"
        }}</b>
        <p>ที่ต้องตรวจสอบชำระเงิน ></p>
      </div>
      <div @click="$router.push('/order/progress')">
        <b class="danger">{{
          order.ship >= 0 ? order.ship.toLocaleString() : "-"
        }}</b>
        <p>ที่ต้องจัดส่ง ></p>
      </div>
      <div @click="$router.push('/order/progress')">
        <b class="warning">{{
          order.receive >= 0 ? order.receive.toLocaleString() : "-"
        }}</b>
        <p>รอลูกค้ารับสินค้า ></p>
      </div>
    </div>
    <hr />
    <div class="items">
      <div @click="$router.push('/manage-product/all')">
        <b class="warning">{{
          product.confirm >= 0 ? product.confirm.toLocaleString() : "-"
        }}</b>
        <p>สินค้ารออนุมัติ ></p>
      </div>
      <div @click="$router.push('/manage-product/all')">
        <b class="danger">{{
          product.edit >= 0 ? product.edit.toLocaleString() : "-"
        }}</b>
        <p>สินค้าที่ต้องแก้ไข ></p>
      </div>
      <div @click="$router.push('/manage-product/all')">
        <b class="danger">{{
          product.suspend >= 0 ? product.suspend.toLocaleString() : "-"
        }}</b>
        <p>สินค้าถูกระงับ ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { product: Object, order: Object },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>