<template>
  <div class="round">
    <input
      type="checkbox"
      :id="id"
      :name="name || false"
      :checked="value"
      @input="$emit('input', $event.target.checked)"
    />
    <label :for="id"></label>
  </div>
</template>

<script>
export default {
  props: { value: Boolean, name: String, id: String },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/checkbox.scss";
</style>