<template>
  <div class="box">
    <p class="boxTitle">ภาพรวมยอดขาย</p>
    <div class="items">
      <div>
        <b class="success">{{
          order.daySales >= 0 ? order.daySales.toLocaleString() : "-"
        }}</b>
        <p>ยอดขายวันนี้</p>
      </div>
      <div>
        <b class="success">{{
          order.monthSales >= 0 ? order.monthSales.toLocaleString() : "-"
        }}</b>
        <p>ยอดขายเดือนนี้</p>
      </div>
      <div>
        <b class="warning">{{
          order.overdue >= 0 ? order.overdue.toLocaleString() : "-"
        }}</b>
        <p>ยอดค้างชำระ</p>
      </div>
    </div>

    <p class="subtitle">ยอดขายย้อนหลัง 6 เดือน</p>
    <canvas id="myChart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  Decimation,
  Filler,
  Legend,
  SubTitle,
  Tooltip,
} from "chart.js";

export default {
  props: { monthly: Array, order: Object },
  watch: {
    monthly() {
      console.log("monthly", this.monthly);
      this.fillChart();
    },
  },
  methods: {
    mounthFomat(date) {
      return new Intl.DateTimeFormat("th", {
        year: "2-digit",
        month: "short",
      }).format(new Date(date));
    },
    sortDate(monthly) {
      return monthly.slice(0).sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    },
    mapData(monthly) {
      const sortMonthly = this.sortDate(monthly);
      return {
        labels: sortMonthly.map((m) => this.mounthFomat(m.date)),
        data: sortMonthly.map((m) => m.net),
      };
    },
    fillChart() {
      Chart.register(
        LineElement,
        PointElement,
        LineController,
        CategoryScale,
        LinearScale,
        Decimation,
        Filler,
        Legend,
        SubTitle,
        Tooltip
      );

      const ctx = document.getElementById("myChart");

      const { labels, data } = this.mapData(this.monthly);

      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              // label: "# of Votes",
              data,
              backgroundColor: [this.$primaryColor + "91"],
              borderColor: [this.$primaryColor + "76"],
              borderWidth: 2,
              tension: 0.5,
            },
          ],
        },
        options: {
          scales: {
            y: {
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            subtitle: {
              display: true,
              text: "ยอดขาย",
              position: "left",
            },
          },
        },
      });
      window.addEventListener("beforeprint", () => {
        myChart.resize();
      });
      window.addEventListener("afterprint", () => {
        myChart.resize();
      });

      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
  mounted() {
    console.log("p:", this.$primaryColor);
  },
};
</script>

<style lang="scss">
@import "./style.scss";

#myChart {
  min-height: 167px;
}
</style>