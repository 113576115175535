<template>
  <div class="box">
    <p class="boxTitle">{{ options.title }}</p>
    <div class="head">
      <div class="checkboxSection">
        <div>
          <Checkbox
            v-model="tickProductThaiBevInput"
            :id="`tick${options.key}`"
          />
          <label :for="`tick${options.key}`">เฉพาะสินค้าไทยเบฟ</label>
        </div>
      </div>
      <div class="wrapSwitch">
        <div
          :class="[{ active: monthSwitch === 1 }, 'mbtn']"
          @click="monthSwitch = 1"
          data-tag="range_month"
        >
          <div class="">เดือนนี้</div>
        </div>
        <div
          :class="[{ active: monthSwitch === 0 }, 'mbtn']"
          @click="monthSwitch = 0"
          data-tag="range_all"
        >
          <div>ทั้งหมด</div>
        </div>
      </div>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <td><small>No.</small></td>
            <td class="hName">{{ options.hName }}</td>
            <td class="hValue">{{ options.hValue }}</td>
          </tr>
        </thead>
        <tbody v-if="data">
          <tr v-for="(item, index) of data" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="des">
              <div>
                <img
                  v-if="item.hasOwnProperty('photo')"
                  :src="item.photo || $placeholderImg"
                  alt=""
                />
                <span>{{ item.name }}</span>
              </div>
            </td>
            <td class="saleVal">{{ item.amount.toPrice() }}</td>
          </tr>
          <tr v-if="data.length === 0 && !loading && !error">
            <td colspan="3" class="message">
              <p>ไม่พบข้อมูล</p>
            </td>
          </tr>
          <tr v-if="error && !loading">
            <td colspan="3" class="message">
              <p>ไม่พบข้อมูล!</p>
            </td>
          </tr>
          <tr v-if="loading">
            <td colspan="3" class="message">
              <p>โหลดข้อมูล...</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox";
import { GET } from "@/utils/http";

export default {
  props: { options: { type: Object } },
  components: { Checkbox },
  data() {
    return {
      monthSwitch: 0,
      error: false,
      data: [],
      tickProductThaiBevInput: false,
      loading: false,
    };
  },
  watch: {
    monthSwitch() {
      this.fetchTopProduct();
    },
    tickProductThaiBevInput() {
      this.fetchTopProduct();
    },
  },
  methods: {
    async fetchTopProduct() {
      try {
        this.data = [];
        this.loading = true;

        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/${this.options.apiPath}?master=${this.tickProductThaiBevInput}&month=${this.monthSwitch}&rank=10&shopType=${this.options.shopType}`
        );
        this.loading = false;

        if (status === 200) {
          this.data = body;
          return;
        }

        throw `${this.options.apiPath}: not ok is status ` + status;
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
  },
  mounted() {
    this.fetchTopProduct();
  },
};
</script>

<style lang="scss">
@import "./style.scss";
@import "./rank.scss";
</style>