<template>
  <RankBox :options="options" />
</template>

<script>
import RankBox from "./rankBox.vue";
export default {
  components: { RankBox },
  data() {
    return {
      options: {
        title: "สินค้าขายดี 10 อันดับ",
        apiPath: "best_sellers",
        hName: "สินค้า",
        hValue: "ยอดขาย",
        key: "topProduct",
      },
    };
  },
};
</script>