
<template>
  <div class="reportPage">
    <div class="title">รายงาน</div>
    <div class="content">
      <FollowCount :product="product" :order="order" />
      <SaleChart :monthly="monthly" :order="order" />
      <TopProduct />
      <TopSales v-if="$myShop.shopType === 'agent'" />
      <TopRetails />
    </div>
  </div>
</template>

<script>
// import reportMockData from "./reportMockData.json";
import { GET } from "@/utils/http";
import FollowCount from "./followCount";
import SaleChart from "./saleChart";
import TopProduct from "./topProduct";
import TopSales from "./topSales";
import TopRetails from "./topRetails";

export default {
  name: "reportPage",
  components: { FollowCount, SaleChart, TopProduct, TopSales, TopRetails },
  data() {
    return {
      order: {},
      product: {},
      monthly: [],
      error: false,
    };
  },
  methods: {
    async fetchDashBoard() {
      try {
        // window.loading(true);

        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/dashboard?month=6`
        );
        // window.loading(false);

        if (status === 200) {
          this.order = body.order;
          this.product = body.product;
          this.monthly = body.monthly;
          return;
        }

        throw "dashboard: not ok is status " + status;
      } catch (error) {
        // window.loading(false);
        this.error = true;
        // await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        // this.$router.back();
      }
    },
  },
  async mounted() {
    await this.fetchDashBoard();

    // mock data
    // this.order = reportMockData.order;
    // this.product = reportMockData.product;
    // console.log({ reportMockData });
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";

.reportPage {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow: auto;
  padding-bottom: 60px;
}

.title {
  font-size: 18px;
  padding: 14px;
  color: #606060;
  font-weight: bold;
}

.content {
  width: 100%;
  padding: 10px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 80vh;
}
</style>
